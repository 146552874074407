import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { GlobalService } from './_core/global.service';
import { BehaviorService } from './_core/_services/behavior.service';
import { EventsService } from './_core/_services/events.service';
import { PosPagoService } from './_core/_services/pos-pago.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {

  public urlPath: string = this.router.url.replace('/', '').split('?')[0].split('/')[0];
  public useCookies: boolean = false;
  public showPopUp: boolean = false;

  public loading_: boolean = false;

  public popupType: any = '';
  public frameUrl: string = '';
  public frameUrlPOS: string = 'https://webportals.cachefly.net/brasil/tim/rock-in-rio/tcs/rg-tim-rir-pos_v2.html';
  public UrlTC: string = 'https://webportals.cachefly.net/brasil/tim/rock-in-rio/tcs/tc-tim-rir.html';

  public cpfExist: boolean = true;

  public showtimmaispage: boolean = false;

  constructor(
    public router: Router,
    private tim: PosPagoService,
    private event: EventsService,
    public global: GlobalService,
    private beh: BehaviorService,
    public sanitizer: DomSanitizer
  ) {
    this.event.eventStart({ eventType: 'APP_INIT', eventCategory: 'INIT' });

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.urlPath = event.urlAfterRedirects.replace('/', '').split('?')[0].split('/')[0];
        window.scrollTo(0, 0);
        if(event.urlAfterRedirects.includes('/timmais')){
          this.showtimmaispage = true;
        }
      }
    });

    this.beh.popUp.subscribe(val => { this.showPopUp = val, this.global.bodyScroll(val) });

    this.useCookies = !sessionStorage._allowCookie;

   
  }

  public haveCode(): boolean {
    return !!sessionStorage.c;
  }

  public goToBenefits() {
    let topVal = (document.querySelector('.benfSection') as HTMLElement)?.offsetTop - 50;
    window.scroll({ top: topVal, left: 0, behavior: 'smooth' });
  }

  public acceptCookies() {
    sessionStorage._allowCookie = true;
    this.useCookies = false;
  }

  public signOut() {
    this.tim.logout().subscribe(res => {
      !!res?.redirectUrl && (location.href = res.redirectUrl);
    }, noRes => { !!noRes.error?.redirectUrl && (location.href = noRes.error.redirectUrl) });
  }

  public loadOffers() {
    this.loading_ = true;

    this.tim.offers().subscribe(offer => {
      if (!(offer.errorTO?.isError)) {
        this.beh.benefitsResp.next(offer?.offersList || []);

        if (offer.rockInRioOpActive && offer.rockInRioRedirectUrl) {
          this.beh.redirectUrl = offer.rockInRioRedirectUrl;
        }

        let userSess = JSON.parse(sessionStorage.u || '{}');
        !!offer?.msisdn && (userSess.m = btoa(offer?.msisdn));
        sessionStorage.u = JSON.stringify(userSess);

      } else {
        offer.errorTO?.code == -7 ? this.router.navigate(['no-post-paid']) : !!offer?.redirectUrl && (location.href = offer.redirectUrl);
      }
      this.loading_ = false;
    }, noOffer => {
      this.loading_ = false;
      noOffer.errorTO?.code == -7 ? this.router.navigate(['no-post-paid']) : !!noOffer.error?.redirectUrl && (location.href = noOffer.error.redirectUrl);
    });
  }

  public validateCPF(cpf: string): boolean {
    let sum = 0;
    let rest;
    let i = 0;

    if (!cpf) return false;

    if (cpf == "00000000000") return false;

    for (i = 1; i <= 9; i++) { sum = sum + parseInt(cpf.substring(i - 1, i)) * (11 - i); }
    rest = (sum * 10) % 11;

    if ((rest == 10) || (rest == 11)) rest = 0;
    if (rest != parseInt(cpf.substring(9, 10))) return false;

    sum = 0;
    for (i = 1; i <= 10; i++) sum = sum + parseInt(cpf.substring(i - 1, i)) * (12 - i);
    rest = (sum * 10) % 11;

    if ((rest == 10) || (rest == 11)) rest = 0;
    if (rest != parseInt(cpf.substring(10, 11))) return false;
    return true;
  }

  public openIframe(cpf?: string) {

    if (cpf) {
      this.tim.validateCpf(cpf).subscribe(
        res => {
          if (res['error'].name == "UNEXISTANT_CPF") {
            this.cpfExist = false;
          } else if (res['error'].name == "SUCCESS") {
            this.cpfExist = true;

            let cpfclean;
            if (cpf) {
              cpfclean = cpf.replace(/\s/g, "");
            }
            this.beh.userCPF.next(cpfclean);
            this.beh.popUp.next(false);
            this.router.navigate(['rock-in-rio']);
          }

        }, error => {
          console.log(error);

        }, () => {

        })
    } else {
      this.router.navigate(['rock-in-rio']);
    }

  }


  public openPopupPim(type: string) {
    this.popupType = type;

    if (type == 'reg') {
      this.frameUrl = 'https://webportals.cachefly.net/brasil/tim/rock-in-rio/tcs/rg-tim-rir-pos_v2.html'
    } else if (type == 'tc') {
      this.frameUrl = 'https://webportals.cachefly.net/brasil/tim/rock-in-rio/tcs/tc-tim-rir.html';
    }
  }

}