import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BehaviorService {

  public benefitsResp = new BehaviorSubject<any[] | undefined>(undefined);
  public redirectUrl = new BehaviorSubject<any[] | undefined>(undefined);
  public popUp = new BehaviorSubject<boolean>(false);
  public userCPF = new BehaviorSubject<string | undefined>(undefined);

}