import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tim-mais',
  templateUrl: './tim-mais.component.html',
  styleUrls: ['./tim-mais.component.scss']
})
export class TimMaisComponent implements OnInit {

  public deeplink: string = 'https://d3s3b.app.goo.gl/?link=https://meutim.tim.com.br/home&apn=br.com.timbrasil.meutim&isi=668591218&ibi=br.com.tim.appmeutim2&utm_campaign=incentivoapp_sms_pre&utm_medium=bannerdI&utm_source=marketing_engagement&efr=1';
  
  constructor() { }

  ngOnInit(): void {
  }

  public openDeepLink(){
    window.open(this.deeplink);
  }
}
