import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalService } from '../global.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class PosPagoService {

  constructor(
    private http: HttpClient,
    private global: GlobalService,
    private router: Router
  ) { }

  public getCode(): string {
    let params = new URLSearchParams(location.search);
    params.has('code') && (sessionStorage.c = params.get('code'));
    if(params.has('code')){
      this.router.navigateByUrl('timmais');
    }
    return params.has('code') ? params.get('code') : sessionStorage.c || '';
  }

  public offers() {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        code: this.getCode()
      })
    };

    return this.http.post<any>(`${this.global.relativeUrl}/pramim/home/base`, {}, httpOptions);
  }

  public validateCpf(cpf: any) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    const body = {
      "operatorId": this.global.operatorId,
      "serviceId": this.global.serviceId,
      "countryId": this.global.countryId,
      "userIdentifier": cpf,
    }

    return this.http.post<any>(`${this.global.relativeUrl}/beapi/authenticate/validateCpf`, body, httpOptions);
  }

  public campaignDetails(request: { campaignId: string }) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        code: this.getCode()
      })
    };

    return this.http.post<any>(`${this.global.relativeUrl}/pramim/home/campaignDetails`, request, httpOptions);
  }

  public redeemOffer(request: { campaignId: string }) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        code: this.getCode()
      })
    };

    return this.http.post<any>(`${this.global.relativeUrl}/pramim/home/redeem`, request, httpOptions);
  }

  public logout() {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        code: this.getCode()
      })
    };

    return this.http.post<any>(`${this.global.relativeUrl}/pramim/login/logout`, {}, httpOptions);
  }

}