import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TimMaisComponent } from './modules/benefits/tim-mais/tim-mais.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./modules/benefits/benefits.module').then(m => m.BenefitsModule)
  },
  {
    path: 'timmais',
    component: TimMaisComponent
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
