<div id="appBase">

    <header *ngIf="!showtimmaispage">
        <div class="container-xl">
            <img (click)="router.navigate([''], { queryParamsHandling: 'merge' })" src="./../assets/images/logo_timpramim.png" alt="Logo Tim">
            <!-- <a (click)="true">{{ 'user name' }} <i class="fas fa-chevron-down"></i></a> -->
            <a *ngIf="haveCode()" (click)="signOut()">Logout <i class="fas fa-sign-out-alt"></i></a>
        </div>
    </header>

    <!-- <section class="mainBanner">
        <div class="container-xl">

            <img *ngIf="!urlPath" src="./../assets/images/headerBackground.png" alt="Lady on phone" class="banner">

            <div class="bannerInfo">
                <!-- <h1>O TIM PRA MIM mudou de fase, e liberou ainda mais benefícios para você</h1> -->
                <!-- <h6>Aproveite preços e as melhores lojas do mercado</h6> --
                <h1>Novidade! Concorra a pares de ingressos de gramado do Rock in Rio Brasil 2022 + R$ 5 MIL para transporte e hospedagem!</h1>
                <h6>Basta se cadastrar para concorrer na nova missão Estrela do Rock. Clique abaixo e não perca!</h6>
                <!-- <a routerLink="/beneficios" (click)="goToBenefits()" queryParamsHandling="merge" class="btn" title="Ver benefícios">Ver benefícios</a> --
                <a (click)="openIframe()" class="btn" title="Ir para a Missão">Ir para a Missão</a>
            </div>

            <img *ngIf="!!urlPath" src="./../assets/images/lady.png" alt="Lady on phone" class="banner_2">

        </div>
    </section> -->

    <section *ngIf="!urlPath" class="know">
        <div class="container-xl">

            <div>
                <h6>Conheça o TIM PRA MIM</h6>
                <h2>Muitos benefícios para você:</h2>
                <a (click)="goToBenefits()" class="btn sec" title="Ver benefícios">Ver benefícios</a>
            </div>

            <div>
                <div class="tip">
                    <img src="./../assets/images/icon_trophy.png" alt="Trophy">
                    <p>O que é?</p>
                    <p>TIM PRA MIM é uma página onde temos descontos exclusivos para clientes Pós-Pago.</p>
                </div>

                <div class="tip">
                    <img src="./../assets/images/icon_pc.png" alt="Pc">
                    <p>Como eu me cadastro e aproveito?</p>
                    <p>Clientes pós-pago não precisam se cadastrar. Então, basta acessar os benefícios e aproveitar!</p>
                </div>
            </div>

            <div>
                <img class="banner_2" src="./../assets/images/lady.png" alt="Lady on phone">
            </div>

        </div>
    </section>

    <router-outlet></router-outlet>

    <footer *ngIf="!showtimmaispage">
        <div class="container-xl">

            <div class="linksUteis">
                <a routerLink="falecomagente" title="Fale com a gente">Fale com a gente</a>
                <a routerLink="termos" title="Termos e condições">Termos e condições</a>
                <a routerLink="regulamento" title="Regulamento">Regulamento</a>
                <a href="https://site.tim.com.br/sp/atendimento/lgpd" target="_blank" title="Central de privacidade">Central de privacidade</a>

                <h5>TIM na rede</h5>
                <div class="social">
                    <a href="https://www.facebook.com/timbrasil" target="_blank" title="Facebook"><i class="fab fa-facebook"></i></a>
                    <a href="https://twitter.com/TIMBrasil" target="_blank" title="Twitter"><i class="fab fa-twitter"></i></a>
                    <a href="https://www.instagram.com/timbrasil" target="_blank" title="Instagram"><i class="fab fa-instagram"></i></a>
                </div>
            </div>

            <div class="appLinks">
                <img src="./../assets/images/logo_timpramim_ver.png" alt="Logo Tim" class="logo">
                <p>Aproveite os benefícios e<br> comodidades do app<br> Meu TIM!</p>
                <a href="https://apps.apple.com/br/app/meu-tim/id668591218" target="_blank"><i class="fab fa-apple"></i></a>
                <a href="https://play.google.com/store/apps/details?id=br.com.timbrasil.meutim&hl=pt_BR" target="_blank"><i class="fab fa-google-play"></i></a>
            </div>

        </div>
    </footer>

    <div *ngIf="showPopUp" class="popBase">
        <div class="popBox">
            <img src="./../assets/images/rio-header.svg" alt="Rock In Rio" class="header">

            <div class="top">
                <img src="./../assets/images/guitar-guy.png" alt="Guitar Guy">
                <p>Quer concorrer a pares de <b>ingressos de gramado do Rock in Rio Brasil 2022 e R$ 5 MIL</b> para ajudar com transporte e
                    hospedagem? É muito fácil! Para você que é cliente <b>TIM Black</b> ou <b>TIM LIVE</b> basta se cadastrar pra concorrer!</p>
            </div>

            <div class="choises">
                <div class="sideCol">
                    <h1>Sou <b>TIM Black</b></h1>
                    <h5>TIM BLACK e TIM BLACK + LIVE</h5>
                    <p>No <b>TIM PRA MIM</b>, além de todos os descontos já disponíveis, você também poderá concorrer a pares de ingressos de gramado do Rock
                        in Rio Brasil 2022 todo mês. Clique no botão abaixo pra se cadastrar!
                        Caso já tenha resgatado os números da sorte deste mês, você também pode consultá-los novamente. Não esqueça de
                        voltar no próximo mês pra participar de um novo sorteio e garantir novas chances!</p>

                    <button (click)="loadOffers()" class="btn" [disabled]="loading_">ACESSAR TIM PRA MIM</button>
                </div>

                <div class="sideCol">
                    <h1>Sou <b>TIM LIVE</b>!</h1>
                    <p>Cadastre-se já inserindo seu CPF abaixo para começar a concorrer aos pares de ingressos de gramado do Rock in Rio Brasil 2022. Fique atento!
                        Serão 2 sorteios por mês.
                        Se já estiver participando, você também pode consultar seus números da sorte novamente. Não esqueça de voltar pra
                        garantir novas chances para o próximo sorteio!</p>

                    <form #cpfForm="ngForm" (submit)="openIframe(cpfForm.value.cpf)">
                        <input ngModel type="tel" name="cpf" id="cpf" minlength="11" maxlength="11" placeholder="Insira seu CPF" [class.alertInputError]="!cpfExist" required>
                        <span class="alert-error" *ngIf="!cpfExist">CPF incorreto ou não encontrado.</span><br>
                        <button type="submit" class="btn notLoading" [disabled]="!validateCPF(cpfForm.value.cpf)">ACESSAR</button>
                    </form>
                </div>                
            </div>

            <a class="link" *ngIf="!global.isIos()" (click)="openPopupPim('reg')" title="Regulamento Estrela Do Rock">Regulamento Estrela Do Rock</a>
            <a class="link" *ngIf="global.isIos()" href="{{frameUrlPOS}}" title="Regulamento Estrela Do Rock">Regulamento Estrela Do Rock</a>
            <br>
            <a class="link" *ngIf="!global.isIos()"(click)="openPopupPim('tc')"  title="Termos e Condições - Estrela Do Rock">Termos e Condições - Estrela Do Rock</a>
            <a class="link" *ngIf="global.isIos()" href="{{UrlTC}}" title="Termos e Condições - Estrela Do Rock">Termos e Condições - Estrela Do Rock</a>
            <!-- <div class="disclamer"> R$ 5 MIL reais serão concedidos apenas para clientes TIM Black e TIM Black + LIVE.</div> -->
        </div>
    </div>

    <div id="popup-mission" class="popUpField popup-tc" *ngIf="popupType != ''">
        <div class="popBox text-center">
            <!-- <div class="close-popUp" (click)="closePopup()"><img src="./assets/images/btn_close.png"/></div> -->
            <div class="row popHeader">
                <div class="col-12">
                    <p *ngIf="popupType == 'reg'">REGULAMENTO</p>
                    <p *ngIf="popupType == 'tc'">TERMOS E CONDIÇÕES</p>
                </div>
            </div>
            <div class="row popbody">
                <iframe class="iframeContent" [src]="sanitizer.bypassSecurityTrustResourceUrl(frameUrl)"></iframe>
            </div>
            <div class="row popFooter mb-2">
              <div class="col-12">
                  <button (click)="popupType = ''" type="button" class="btn btn-green" >Fechar</button>
              </div>
            </div>
        </div>
    </div>

    <div *ngIf="useCookies" class="cookieBasket">
        <div class="container-xl">
            <p>Para melhorar sua experiência de navegação, este site utiliza cookies. Ao clicar em 'Aceitar', você consente estar de acordo com nossa <a href="https://www.tim.com.br/sobre-a-tim/institucional/seguranca/politica-de-privacidade" target="_blank" title="Cookies Policy">Política de Cookies</a></p>
            <button (click)="acceptCookies()">Aceitar</button>
        </div>
    </div>

</div>