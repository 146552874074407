import { Injectable } from '@angular/core';
import { UUID } from "angular2-uuid";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  public readonly relativeUrl: string = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;

  public readonly serviceId: number = 169792;
  public readonly operatorId: number = 4;
  public readonly countryId: number = 55;

  public readonly events: any = {
    timweBi: true,
    analyticsId: null,
    firebase: false
  }

  constructor() { }

  public bodyScroll(open: boolean) {
    open ? document.body.classList.add('no-scroll') : document.body.classList.remove('no-scroll');
  }

  public getQueryParams() {
    let result: any = {};
    let _params = new URLSearchParams(location.search) as any;
    for (let param of _params) { result[param[0]] = param[1] }
    return result;
  }

  public getCookies() {
    let ck: any = {};
    for (let c of document.cookie.split('; ')) { ck[c.split('=')[0]] = c.split('=')[1] }
    return ck;
  }

  public setConcentCookie() {
    let expireDate = new Date(`${new Date().getFullYear() + 2}-${new Date().getMonth() + 1}-${new Date().getDay()}`);
    document.cookie = `_timUCC=true; expires=${expireDate}`;
  }


  public generateRandomId(): string {
    return UUID.UUID();
  }

  public generateDeviceId() {
    let user = JSON.parse(localStorage.u || '{}');

    if (user.d) {
      return user.d;
    } else {
      user.d = UUID.UUID();
      window.localStorage.u = JSON.stringify(user);
      return user.d;
    }
  }

  public generateSessionId() {
    let userSession = JSON.parse(sessionStorage.u || '{}');

    if (userSession.s) {
      return userSession.s;
    } else {
      userSession.s = UUID.UUID();
      window.sessionStorage.u = JSON.stringify(userSession);
      return userSession.s;
    }
  }

  // Detects if device is on iOS
  public isIos(){
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod|macintosh/.test(userAgent);
  }
  
}