<div id="tim-mais" >
    <div class="timmais-container">
        <div class="col-12">
            <p class="paragraph-blue">🎉 Temos Novidades para Você! 🎉</p>
        </div>
        <div class="col-12">
            <h1>O TIM PARA MIM evoluiu e agora é o TIM Mais !</h1>
            <p class="paragraph m-0">A partir de agora, você terá mais beneficios, experiências exclusivas e um mundo de vantagens incríveis no TIM Mais.</p>
            <p class="paragraph-blue">Tudo isso já está disponivel no app Meu TIM, onde você também pode gerenciar seu plano de forma prática e rápida.</p>
        </div>
        <div class="col-12 d-flex align-items-center">
            <img class="img-phone" src="https://webportals.cachefly.net/brasil/tim/timpramim/images/phone-meutim-app.svg">
            <p>Baixe o app Meu TIM e descubra todas as novidades do TIM Mais!</p>
        </div>
        <div class="col-12 text-center">
            <button class="btn btn-white m-auto" (click)="openDeepLink()">
                <img src="https://webportals.cachefly.net/brasil/tim/timpramim/images/download-icon.svg">
                Baixar app Meu TIM
            </button>
        </div>
    </div>
</div>